import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerModule } from '@accubits/spinner'
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { ToastrModule } from 'ngx-toastr'

import { RequestInterceptor } from '../app/core/interceptors/request.interceptors'
import { ResponseInterceptor } from '../app/core/interceptors/response.interceptors'

import { NgxSpinnerModule } from "ngx-spinner";

import { NgIdleModule } from '@ng-idle/core'

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SpinnerModule,
    NgxMaskModule.forRoot(maskConfig),
    ToastrModule.forRoot({
      closeButton : true,
      preventDuplicates: true,
      maxOpened: 0,
      timeOut: 100000,
    }),
    NgxSpinnerModule,
    NgIdleModule.forRoot(),
  ],
  exports: [
    ToastrModule,
    NgxSpinnerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  bootstrap: [AppComponent]
})
export class AppModule {

}
